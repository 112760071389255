<template>
  <div
    v-if="tableState.showTable"
    class="info-component"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <info-box />
  </div>
</template>

<script lang="ts">
import { tableState } from '@/stores'
import {
  InfoBox,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InfoComponent',
  components: {
    InfoBox
  },
  mixins: [WindowAspect],
  data() {

    return {
      tableState: tableState()
    }

  }
})
</script>

<style lang="less" scoped>
.info-component {
   position: absolute;
   bottom: .5rem;
   width: 100%;

   &.mobile-info-component {
       bottom: 1.5rem;
   }
}
</style>
