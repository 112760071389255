import { defineStore } from 'pinia'

export interface UiState {
  showTimeKeeper: boolean,
  showSplitTimes: boolean,
  showFinishTopBox: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean
}

const initialState = (): UiState => ({
  showTimeKeeper: false,
  showSplitTimes: false,
  showFinishTopBox: false,
  showTrainingLayout: false,
  isTraining: false
})

export const uiState = defineStore('uiState', {
  state: initialState
})
