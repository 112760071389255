import {
  AudioGroups,
  type DisciplinePhaseManager
} from '../../types'
import { playerAnimationManager } from '@/app/entities/player/PlayerAnimationManager'
import {
  drivePhaseConfig,
  gameConfig,
  speedConfig
} from '@/app/config'
import { player } from '@/app/entities/player'
import {
  audioManager,
  cameraManager,
  gsap,
  modes
} from '@powerplay/core-minigames'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import { pushbarManager } from '@/app/PushbarManager'
import {
  actionButtonState,
  inputsState,
  movementButtonsState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class DrivePhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /** Pocitadlo frameov pre vypnutie force forward */
  private frameCounterToDisableForceForward = 0

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('drive phase started')
    inputsState().actionVisible = false

    if (!modes.isTutorial()) {

      this.showMobileButtons()

    }

    playerAnimationManager.forwardAnimation()

    // az po x frameoch prestane posobit forceForward
    const framesToDisableFF = speedConfig.framesAfterStartToDisableForceForward
    console.log(`force forward budeme vypinat po ${ framesToDisableFF }frameoch`)
    this.frameCounterToDisableForceForward = framesToDisableFF

    player.changeCameraSettings(
      drivePhaseConfig.cameraSettings.idealOffset,
      drivePhaseConfig.cameraSettings.idealLookAt,
      drivePhaseConfig.cameraSettings.coefSize,
      drivePhaseConfig.cameraSettings.changeLerp,
      drivePhaseConfig.cameraSettings.isStaticMovement
    )

    cameraManager.changeRenderSettings(0.1)

    gsap.to({}, {
      duration: 2,
      onComplete: player.setEnabledOptimalizationForDrive,
      onCompleteParams: [true],
      callbackScope: player
    })

    // skipnutie az na koniec kvoli debugu
    if (gameConfig.skipToFinish.active) {

      player.hillLinesManager.setActualPercent(gameConfig.skipToFinish.percent)

    }

  }

  /**
   * zahrame komentatora
   */
  public playCommentAudioAfterStart(): void {

    if (audioManager.isAudioGroupPlaying(AudioGroups.commentators)) return

    audioManager.play(pushbarManager.audioCommentAfterStart)

  }

  public showMobileButtons(): void {

    actionButtonState().$patch({
      showJoystick: false,
      isStart: false
    })
    movementButtonsState().isActive = true
    inputsState().$patch({
      disabled: false,
      isVisible: true
    })

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    if (this.frameCounterToDisableForceForward > 0) {

      this.frameCounterToDisableForceForward--
      if (this.frameCounterToDisableForceForward === 0) {

        // vypneme forceForward
        speedManager.setEnabledForceForward(false)
        console.log('VYPINAME force forward')

      }

    }

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('drive phase ended')
    this.callbackEnd()

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
