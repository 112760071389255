<template>
  <power-stick
    :only-horizontal="movementState.onlyHorizontal"
    @position-x="changeMovementX"
    @position-y="changeMovementY"
    @end-contact="endContact"
  />
</template>

<script lang="ts">
import { PowerStick } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { inputsManager } from '@powerplay/core-minigames'
import { movementState } from '@/stores'

export default defineComponent({
  name: 'JoyStick',
  components: {
    PowerStick
  },
  data() {

    return {
      movementState: movementState()
    }

  },
  methods: {
    endContact() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()
      movementState().$patch({
        positionX: 0,
        positionY: 0
      })

    },
    changeMovementX(positionX: number) {

      movementState().positionX = positionX

    },
    changeMovementY(positionY: number) {

      movementState().positionY = positionY

    }
  }
})
</script>
