<template>
  <mobile-button
    :is-scaled="false"
    :disabled="inputsState.disabled"
    type="start-luge"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import { inputsManager } from '@powerplay/core-minigames'
import { actionButtonState } from '@/stores'
import { inputsState } from '@/stores'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  data() {

    return {
      inputsState: inputsState()
    }

  },
  methods: {
    performAction() {

      actionButtonState().touchStart = true

    },
    endAction() {

      actionButtonState().touchStart = false
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style>

</style>
