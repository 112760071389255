<template>
  <section
    class="positioner"
  >
    <section
      v-if="tutorialState.buttons.showMovement || tutorialState.buttons.showAction"
    >
      <section
        v-if="!actionButtonState.showJoystick && tutorialState.buttons.showMovement"
        class="tutorial-arrows-container"
      >
        <div
          class="arrow"
          style="position: absolute; left: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; bottom: 0; transform-origin: bottom left"
            position="bottom"
          />
        </div>
        <div
          class="arrow"
          style="position: absolute; right: 0; bottom: 0%;"
        >
          <arrow-animation
            style="position: absolute; width: 580px; height: 460px; bottom: 0; transform-origin: bottom right"
            position="bottom"
          />
        </div>
      </section>
      <movement-sides
        v-if="!actionButtonState.showJoystick && tutorialState.buttons.showMovement"
        glow
        @position="changeMovement"
        @end-contact="endContact"
      />
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between bottom-right"
        :style="{transform: `scale(${scaleCoef})`}"
      >
        <joy-stick
          v-if="actionButtonState.showJoystick && tutorialState.buttons.showMovement"
          glow
        />
        <section
          v-else
          class="placeholder"
        />
        <section v-show="!tutorialState.buttons.showMovement" />
        <section
          class="relative"
        >
          <arrow-animation
            v-if="tutorialState.buttons.showAction"
            position="bottom"
            :style="{
              position: 'absolute',
              'transform-origin': '100% 100%',
              'pointer-events': 'none',
              width: '580px',
              height: '460px',
              bottom: 0
            }"
            :is-scaled="false"
          />
          <mobile-button
            v-if="tutorialState.buttons.showAction"
            :is-scaled="false"
            type="start-luge"
            glow
            @mousedown.self="performAction"
            @mouseup.stop=""
            @touchstart.self="performAction"
            @touchend.stop=""
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between bottom-left"
        :style="{transform: `scale(${scaleCoef})`}"
      >
        <section
          class="relative"
        >
          <arrow-animation
            v-if="tutorialState.buttons.showAction"
            position="bottom"
            :style="{
              position: 'absolute',
              'transform-origin': '100% 100%',
              'pointer-events': 'none',
              width: '580px',
              height: '460px',
              bottom: 0
            }"
            :is-scaled="false"
          />
          <mobile-button
            v-if="tutorialState.buttons.showAction"
            :is-scaled="false"
            type="start-luge"
            glow
            @mousedown.self="performAction"
            @mouseup.stop=""
            @touchstart.self="performAction"
            @touchend.stop=""
          />
        </section>
        <joy-stick
          v-if="actionButtonState.showJoystick && tutorialState.buttons.showMovement"
          glow
        />
        <section
          v-else
          class="placeholder"
        />
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import JoyStick from '../Inputs/JoyStick.vue'
import {
  ArrowAnimation,
  MovementSides,
  MobileButton,
  WindowAspect,
  tutorialCoreState,
  gameSettingsState
} from '@powerplay/core-minigames-ui'
import { defineComponent } from 'vue'
import {
  inputsManager,
  tutorialManager
} from '@powerplay/core-minigames'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { movementState } from '@/stores/movementState'
import {
  actionButtonState,
  tutorialState
} from '@/stores/index'

export default defineComponent({
  name: 'TutorialMobileInputs',
  components: {
    JoyStick,
    MovementSides,
    ArrowAnimation,
    MobileButton
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      tutorialState: tutorialState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  methods: {
    endContact() {

      movementState().positionX = 0

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      movementState().positionX = positionX

    },
    performAction() {

      if (!actionButtonState().isJumpIn) return // aby neskipovalo na starte pocast typewriteru
      if (tutorialManager.getTypeWriting()) {

        tutorialCoreState().typeWriter = false
        tutorialManager.setTypeWriting(false)

      } else if (!tutorialFlow.isEventActive) {

        tutorialFlow.eventActionPressed()

      }

    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.placeholder {
  width: 300px;
}

.buttons-position {
  position: absolute;
  top: 0;
  left: 45%;
  transform: translate(-50%);
}
.tutorial-arrows-container {
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .arrow {
        width: 580px;
        height: 100%;
        position: relative;
    }
}
.bottom-right {
  transform-origin: bottom right;
  display: flex;
  justify-content: flex-end;
}
.bottom-left {
  transform-origin: bottom left;
  display: flex;
  justify-content: flex-start;
}
</style>
