import { defineStore } from 'pinia'

export interface StartPhaseState {
  clickedPower: number,
  soundTimer: number,
  showName: boolean,
  showPhase: boolean,
  showBar: boolean,
  attempt: number
}

const initialState = (): StartPhaseState => ({
  clickedPower: 0,
  soundTimer: 0,
  showName: false,
  showPhase: false,
  showBar: false,
  attempt: 0
})

export const startPhaseState = defineStore('startPhaseState', {
  state: initialState
})

