import {
  gsap,
  modes,
  audioManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  startUpPhaseConfig
} from '../config'
import { endManager } from '../EndManager'
import { player } from '../entities/player'
import { playerAnimationManager } from '../entities/player/PlayerAnimationManager'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import { tutorialObjectives } from '../modes/tutorial/TutorialObjectives'
import { disciplinePhasesManager } from '../phases/DisciplinePhasesManager'
import type { StartPhaseManager } from '../phases/StartPhase/StartPhase'
import type { StartUpPhaseManager } from '../phases/StartUpPhase/StartUpPhase'
import { speedManager } from '../SpeedManager/SpeedManager'
import {
  AudioNames,
  TutorialEventType,
  TutorialObjectiveIds,
  DisciplinePhases,
  LugeAnimationsNames,
  PlayerAnimationsNames,
  type DisplayMessage
} from '../types'
import {
  jumpInMessageState,
  pushBarState
} from '@/stores'

/**
 * Trieda na spravu pushbaru
 */
export class PushbarManager {

  /** bar visibility */
  private visible = false

  /** bar fill value */
  private barValue = 0

  /** clicked value of push */
  private clickedPower = 0

  /** show mark */
  private showMark = false

  /** clicked actual push */
  private clicked = false

  /** array of push values */
  public pushes: number[] = []

  /** pushing started */
  public started = false

  /** bar ide dole */
  private descending = false

  /** gsap baru */
  private barTween?: gsap.core.Timeline

  /** ako dlho sa bude message zobrazovat */
  private JUMP_IN_MESSAGE_DURATION = 3

  /** pause */
  private paused = false

  /** Kvalita kliku startu */
  public startClickedQuality = 0

  /** Pocet klikov */
  public clickCount = 0

  /** Aky komentator sa ma zahrat na konci startus */
  public audioCommentAfterStart!: AudioNames

  /** aktualny push index */
  private activeIndex = 0

  /** blokovanie inputu, kym sa nepusti */
  public blockedInput = false

  private hideTween!: gsap.core.Tween

  /** initiate pushing */
  public startPush(): void {

    this.visible = true

    this.runNextPush()

  }

  /**
   * manage click push
   * @param forced - Vynuteny push pocas idealAutoMove
   */
  public managePush(forced = false): void {

    if (this.clicked || (gameConfig.idealAutoMove.start && !forced)) return

    if (!this.started) {

      const startPhase = disciplinePhasesManager
        .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager

      this.startClickedQuality = this.barValue
      playerAnimationManager.preStart2Animation()
      if (!modes.isTutorial() || tutorialFlow.isResetedOnce) {

        playerAnimationManager.preStart2Animation()

      }
      startPhase.finishPhase()

    }

    this.clicked = true
    this.clickedPower = this.barValue
    this.pushes.push(this.clickedPower)
    this.showMark = true
    console.log('clicked power:', this.clickedPower)
    this.storeState()

  }

  /**
   * update
   */
  public update(): void {

    if (this.paused) return

    let time = this.barValue / 2
    if (this.descending) {

      time = 50 + (100 - this.barValue) / 2

    }

    // console.log('time ', time, 'bar ', this.barValue, 'desc', this.descending, this.started)
    player.animationsManager.manualyUpdateTimeByPercent(
      this.started ? PlayerAnimationsNames.start : PlayerAnimationsNames.prestart,
      time
    )

    if (!this.started) {

      player.lugeAnimationsManager.manualyUpdateTimeByPercent(
        LugeAnimationsNames.prestart,
        time
      )

    }

    this.storeState()

    // pri automove davame force push
    if (gameConfig.idealAutoMove.start && this.barValue === 100) this.managePush(true)

  }

  /**
   * pause/play
   * @param pause - pause/play bool
   */
  public togglePause(pause = !this.paused): void {

    this.paused = pause
    if (this.paused) {

      this.barTween?.pause()

    } else {

      this.barTween?.play()

    }

  }

  /** start next push */
  private runNextPush(): void {

    this.showMark = false
    if (this.pushes.length >= 5) {

      this.endStartUp()
      return

    }

    const { afterStart, start } = startUpPhaseConfig.pushBarDuration
    const duration = this.started ? afterStart : start
    const objectToTween = { barValue: 0 }
    let startedSoundPlayer = false
    this.barTween = gsap.timeline().to(objectToTween, {
      onComplete: () => {

        this.descending = true

      },
      onUpdate: () => {

        this.barValue = Math.ceil(objectToTween.barValue)

        /*
         * ak uz bolo odstartovane a este sa neprehral zvuk startu, tak ho prehrame
         * prehravame ho skor, aby to sedelo na animaciu ruk na zemi
         */
        if (this.started && !startedSoundPlayer && this.barValue > 70) {

          startedSoundPlayer = true
          // musime vypnut zvuk, lebo su viacere za sebou a inak by sa neprehrali dalsie
          audioManager.stopAudioByName(AudioNames.start)
          audioManager.play(AudioNames.start)

        }

      },
      barValue: 100,
      duration,
      ease: 'power1.out'
    }).to(objectToTween, {
      onComplete: this.resolvePush,
      onUpdate: () => {

        this.barValue = Math.ceil(objectToTween.barValue)

      },
      barValue: 0,
      callbackScope: this,
      duration,
      ease: 'power1.in'
    })

  }

  /** koniec rozbehu */
  private endStartUp(): void {

    this.showTakeoffMessage()
    const startUpPhase = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.startUp) as StartUpPhaseManager

    startUpPhase.finishPhase()
    this.hideTween = gsap.to({}, {
      duration: startUpPhaseConfig.pushBarHideDelay,
      onComplete: () => {

        this.visible = false
        this.storeState()

      }
    })
    this.storeState()

    console.log(this.clickCount)
    if (modes.isTutorial() && this.clickCount < 3) {

      tutorialObjectives.failObjective(TutorialObjectiveIds.runUp)
      tutorialFlow.eventActionTrigger(TutorialEventType.startFailed)

    } else {

      tutorialObjectives.passObjective(TutorialObjectiveIds.runUp)
      endManager.wasStartupSuccess = true

    }

  }

  /** resolve actual push */
  private resolvePush(): void {

    this.descending = false
    if (this.started || this.clicked) {

      // zmena forward force start podla kliknutia
      speedManager.changeForwardForce(this.clickedPower)

      this.clickedPower = 0
      this.clicked = false

    }
    if (this.started || (!this.started && this.clicked)) {

      if (this.pushes[this.activeIndex] === undefined) {

        this.pushes.push(0)

      } else {

        this.clickCount += 1
        console.log(this.clickCount)

      }
      this.activeIndex += 1

    }
    this.runNextPush()

  }

  /**
   * Po prestart2 spravime start a vynulujeme bar
   */
  public afterPrestart2(): void {

    this.togglePause(false)
    this.barTween?.kill()
    this.barValue = 0
    this.descending = false
    this.started = true
    this.clicked = false
    playerAnimationManager.startAnimation()
    this.resolvePush()

  }

  /**
   * Vratenie kvality za pocet podarenych pokusov
   * @param includeStart - ci ma pocitat aj startovaci klik
   * @returns Kvalita
   */
  public getPushQuality(includeStart = false): number {

    let pushesWithoutStart = this.pushes
    if (!includeStart) {

      pushesWithoutStart = this.pushes.slice(1)

    }

    return (pushesWithoutStart.reduce((partialSum, a) => partialSum + a, 0) /
        pushesWithoutStart.length) || 0

  }


  /**
   * zobrazime startovu spravu
   */
  private showTakeoffMessage(): void {

    const message = this.getJumpInMessage()
    jumpInMessageState().$patch({
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

    gsap.to({}, {
      duration: this.JUMP_IN_MESSAGE_DURATION,
      onComplete: () => {

        jumpInMessageState().$patch({
          showMessage: false,
          messageText: message.message,
          messageColor: message.color
        })

      }
    })

  }
  /**
   * ziskame startovu spravu
   */
  private getJumpInMessage(): DisplayMessage {

    const message = { message: 'slowStart',
      color: 2 }
    this.audioCommentAfterStart = AudioNames.commentAfterStart4

    const pushQuality = pushbarManager.getPushQuality(true) / 100
    console.log(`success clicks quality ${pushQuality}`)

    if (pushQuality >= 0.96) {

      endManager.perfectStartsLog += 1
      message.message = 'perfectStart'
      message.color = 0
      this.audioCommentAfterStart = AudioNames.commentAfterStart1

    } else if (pushQuality >= 0.8) {

      message.message = 'excellentStart'
      message.color = 0
      this.audioCommentAfterStart = AudioNames.commentAfterStart2

    } else if (pushQuality >= 0.5) {

      message.message = 'goodStart'
      message.color = 1
      this.audioCommentAfterStart = AudioNames.commentAfterStart3

    }

    return message

  }

  /**
   * UI update
   */
  private storeState(): void {

    pushBarState().$patch({
      visible: this.visible,
      barValue: this.barValue,
      showMark: this.showMark,
      mark: this.clickedPower,
      pushes: this.pushes
    })

  }

  public reset(): void {

    this.visible = false
    this.barValue = 0
    this.clickedPower = 0
    this.showMark = false
    this.clicked = false
    this.pushes = []
    this.started = false
    this.descending = false
    this.barTween?.kill()
    this.JUMP_IN_MESSAGE_DURATION = 3
    this.paused = false
    this.startClickedQuality = 0
    this.clickCount = 0
    this.activeIndex = 0
    this.blockedInput = false
    this.hideTween?.kill()

  }

}

export const pushbarManager = new PushbarManager()
