import { defineStore } from 'pinia'

export interface SplitTime { text: string, color: string }
export interface SplitTimeState {
  splitTimes: SplitTime[],
  actualTime: string,
  actualPosition: number,
  noSplitTimes: boolean
}

const initialState = (): SplitTimeState => ({
  splitTimes: [],
  actualTime: '',
  actualPosition: 0,
  noSplitTimes: false
})

export const splitTimeState = defineStore('splitTimeState', {
  state: initialState,
  actions: {
    addSplitTime(splitTime: SplitTime) {

      this.splitTimes.push(splitTime)

    }
  }
})
