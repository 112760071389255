import { defineStore } from 'pinia'

export interface JumpInMessageState {
  showMessage: boolean,
  messageText: string,
  messageColor: number
}

const initialState = (): JumpInMessageState => ({
  showMessage: false,
  messageText: '',
  messageColor: 0
})

export const jumpInMessageState = defineStore('jumpInMessageState', {
  state: initialState
})
