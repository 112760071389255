import { defineStore } from 'pinia'

export interface PushBarState {
  visible: boolean,
  barValue: number,
  mark: number,
  showMark: boolean,
  pushes: number[]
}

const initialState = (): PushBarState => ({
  visible: false,
  barValue: 0,
  mark: 0,
  showMark: false,
  pushes: []
})

export const pushBarState = defineStore('pushBarState', {
  state: initialState
})
