import {
  gameConfig,
  movementConfig,
  tutorialConfig
} from '@/app/config'
import { inputsManager } from '@/app/InputsManager'
import { speedManager } from '@/app/SpeedManager/SpeedManager'
import {
  CurveTypes,
  SectionTypes,
  SectorFullTurnTypes,
  Sides,
  TriggersTypes,
  TutorialEventType
} from '@/app/types'
import { movementState } from '@/stores'
import { player } from '.'
import { triggersManager } from '../trigger/TriggersManager'
import { gsap } from '@powerplay/core-minigames'
import { playerAnimationManager } from './PlayerAnimationManager'
import { trainingTasks } from '@/app/modes/training'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import {
  impactState,
  mainState
} from '@/stores'

/**
 * Trieda pre manazer pohybu do stran
 */
export class PlayerMovementManager {

  /** Aktualne percento v priereze krivky pohybu */
  private actualPercent = movementConfig.center

  /** Aktualne percento v priereze krivky pohybu vpredu */
  private actualPercentFront = movementConfig.center

  /** Aktualne virtualne percento v prirereze krivky pohybu, kvoli zakrutam */
  private virtualPercent = movementConfig.center

  /** Aktualne virtualne percento v prirereze krivky pohybu, kvoli zakrutam, vpredu */
  private virtualPercentFront = movementConfig.center

  /** Ovladanie fyziky on/off */
  private moveLeftRight = false

  /** Ci sme aktualne v zakrute alebo nie */
  private inTurn = false

  /** Ci sme aktualne v strede zakruty, kde je najsirsia */
  private inFullTurn = false

  /** Ci sme aktualne v pravotocivej zakrute */
  private inTurnLeftToRight = false

  /** Ci sme aktualne v lavotocivej zakrute */
  private inTurnRightToLeft = false

  /** Aktualny limit vlavo */
  private limitLeft = 0

  /** Aktualny limit vpravo */
  private limitRight = 0

  /** Penalizacia za vyjazd zo zakruty */
  private exitTurnPenalty = 0

  /** Pocet frameov, ktore este ostavaju na penalizaciu za vyjazd zo zakruty */
  private exitTurnPenaltyFrame = 0

  /** Sila pre odraz od mantinela */
  private reboundForce = 0

  /** Pocet frameov, ktore este ostavaju na odraz od mantinela */
  private reboundForceFrame = 0

  /** Specialny dividet pri rebounde */
  private reboundForceDivider = 0

  /** pocet framov stlaceny input */
  private inputFrame = 0

  /** tlacidlo stlacene predchadzajuci frame */
  private previousPressed: Sides | undefined

  /** Tween pre efekt narazu */
  private impactEffectTween: gsap.core.Tween | undefined

  /** Pocitadlo frameov po naraze */
  private frameCounterAfterImpact = 0

  /** pocet framov po prvom impacte */
  private framesAfterFirstImpact = 0

  /** ci pocitat framy po prvom impacte */
  private countFramesAfterImpact = false

  /**
   * Vratenie aktualnych virtualnych percent pohybu do stran
   * @returns % pohybu do stran
   */
  public getVirtualPercent(): number {

    return this.virtualPercent

  }

  /**
   * Vratenie aktualnych virtualnych percent pohybu do stran vpredu
   * @returns % pohybu do stran vpredu
   */
  public getVirtualPercentFront(): number {

    return this.virtualPercentFront

  }

  /**
   * Vratenie aktualnych percent pohybu do stran
   * @returns % pohybu do stran
   */
  public getActualPercent(): number {

    return this.actualPercent

  }

  /**
   * Vratenie aktualnych percent pohybu do stran vpredu
   * @returns % pohybu do stran vpredu
   */
  public getActualPercentFront(): number {

    return this.actualPercentFront

  }

  /**
   * Nastavenie aktualnych percent napevno (napr kvoli automove)
   * @param percent - Hodnota percent
   */
  public setActualPercent(percent: number): void {

    this.actualPercent = percent

  }

  /**
   * Nastavenie aktualnych percent napevno (napr kvoli automove) vpredu
   * @param percent - Hodnota percent vpredu
   */
  public setActualPercentFront(percent: number): void {

    this.actualPercentFront = percent

  }

  /**
   * Metoda na moznost blokovania otacania
   * @param state - boolean stavu moveLeftRight
   */
  public changeMoveLeftRightActive(state: boolean): void {

    this.moveLeftRight = state

  }

  /**
   * Pohyb na lavu stranu
   * @param inTurn - True, ak sme v zakrute
   */
  private toLeftSide(inTurn: boolean): void {

    playerAnimationManager.turningLeft()
    this.resolveInputFrame(Sides.LEFT)

    // ak je offset sani doprava a stlacame dolava, tak nedavame klasicky step ale iba rotujeme
    const offset = Math.round((this.actualPercent - this.actualPercentFront) * 1000) / 1000
    const step = offset < 0 ? 0 : this.getStep(inTurn)

    this.actualPercent -= step

    const { maxOffsetForFrontActualPercent, stepFrontOnInput } = movementConfig
    const stepFront = (offset < maxOffsetForFrontActualPercent) ? stepFrontOnInput : 0

    this.actualPercentFront -= (step + stepFront)
    const newOffset = this.actualPercent - this.actualPercentFront

    // ak preslo akurat teraz maximom, tak zarovname
    if (stepFront !== 0 && newOffset < maxOffsetForFrontActualPercent) {

      this.actualPercentFront = this.actualPercent - maxOffsetForFrontActualPercent

    }

  }

  /**
   * Pohyb na pravu stranu
   * @param inTurn - True, ak sme v zakrute
   */
  private toRightSide(inTurn: boolean): void {

    playerAnimationManager.turningRight()
    this.resolveInputFrame(Sides.RIGHT)

    // ak je offset sani dolava a stlacame doprava, tak nedavame klasicky step ale iba rotujeme
    const offset = Math.round((this.actualPercent - this.actualPercentFront) * 1000) / 1000
    const step = offset > 0 ? 0 : this.getStep(inTurn)

    this.actualPercent += step

    const { maxOffsetForFrontActualPercent, stepFrontOnInput } = movementConfig
    const stepFront = (offset > -maxOffsetForFrontActualPercent) ? stepFrontOnInput : 0

    this.actualPercentFront += (step + stepFront)
    const newOffset = this.actualPercent - this.actualPercentFront

    // ak preslo akurat teraz maximom, tak zarovname
    if (stepFront !== 0 && newOffset < -maxOffsetForFrontActualPercent) {

      this.actualPercentFront = this.actualPercent + maxOffsetForFrontActualPercent

    }

  }

  /**
   * Vyhodnotenie input frame-u
   * @param pressedSide - Stlacena strana inputu
   */
  private resolveInputFrame(pressedSide: Sides): void {

    if (this.previousPressed !== pressedSide) {

      this.inputFrame = 0

    }
    if (this.inputFrame < movementConfig.steeringSpeed) {

      this.inputFrame += 1

    }
    this.previousPressed = pressedSide

  }

  /**
   * Krok
   * @returns number
   */
  private getStep(inTurn: boolean): number {

    return inTurn ?
      this.inputFrame / movementConfig.steeringSpeed * movementConfig.maxSteeringCurve :
      this.inputFrame / movementConfig.steeringSpeed * movementConfig.maxSteeringStraight

  }

  /**
   * Skontrolovanie limitov vlavo
   */
  private checkLimitsLeft(): void {

    let impact = false
    let front = false

    if (this.actualPercent < this.limitLeft) {

      this.actualPercent = this.limitLeft
      impact = true

    }

    if (this.actualPercentFront < this.limitLeft) {

      this.actualPercentFront = this.limitLeft
      impact = true
      front = true

    }

    if (impact) this.onImpact(Sides.LEFT, front)

  }

  /**
   * Skontrolovanie limitov vpravo
   */
  private checkLimitsRight(): void {

    let impact = false
    let front = false

    if (this.actualPercent > this.limitRight) {

      this.actualPercent = this.limitRight
      impact = true

    }
    if (this.actualPercentFront > this.limitRight) {

      this.actualPercentFront = this.limitRight
      impact = true
      front = true

    }

    if (impact) this.onImpact(Sides.RIGHT, front)

  }

  /**
   * Spravenie veci pri naraze
   * @param side - Strana narazu
   * @param front - ci bol naraz spredu
   */
  private onImpact(side: Sides, front = false): void {

    this.actualPercentFront = this.actualPercent

    const { stepCoef, frames, divider } = movementConfig.rebound
    const coef = side === Sides.RIGHT ? -1 : 1

    // ak je opatovny naraz, ale na opacnu stranu este pocas reobundu, tak to predelime
    const oppositeSide = (side === Sides.LEFT && this.reboundForce < 0) ||
            (side === Sides.RIGHT && this.reboundForce > 0)

    const frozen = this.frameCounterAfterImpact < movementConfig.sameSideImpactFreezeFrames
    if (frozen && !oppositeSide) {

      this.frameCounterAfterImpact = 0
      return

    }

    // aby sme zamedzili ping pong efektu od mantinelu ku mantinelu, tak davame speci divider
    const specialDivider = this.reboundForceFrame > 0 && (oppositeSide ||
            this.frameCounterAfterImpact < movementConfig.sameSideImpactFreezeFrames)
    this.reboundForceDivider = specialDivider ? divider : 1

    this.reboundForce = (stepCoef / this.reboundForceDivider) * speedManager.getActualSpeed() *
            coef
    this.reboundForceFrame = frames
    const frontText = front ? 'FRONT' : ''
    console.log(
      `naraz ${side} ${frontText}, rebound sila ${this.reboundForce}`,
      `divider ${this.reboundForceDivider}`
    )
    speedManager.slowDownAfterImpact()

    this.showImpactEffect(side)

    this.frameCounterAfterImpact = 0

    trainingTasks.countImpact(specialDivider)
    if (this.framesAfterFirstImpact === 0) this.countFramesAfterImpact = true

  }

  /**
   * Zobrazenie impact grafiky
   * @param side - strana narazu
   */
  private showImpactEffect(side: Sides) {

    impactState().$patch({
      side,
      isVisible: true
    })
    this.impactEffectTween?.kill()
    this.impactEffectTween = gsap.to({}, {
      duration: 0.5,
      onComplete: this.hideImpactEffect
    })

  }

  /**
   * Schovanie impact grafiky
   */
  private hideImpactEffect(): void {

    impactState().isVisible = false

  }

  /**
   * Nastavenie penalizacie za vyjazd zo zakruty
   * @param offsetFromIdeal - Hodnota offsetu od idealnej linie
   */
  public setExitTurnPenalty(offsetFromIdeal: number): void {

    const sectorData = player.hillLinesManager.getHillLineNormalizer().getActualSectorData()
    const leftToRight = sectorData.type === TriggersTypes.sectorLeftToRightEnd
    const rightToLeft = sectorData.type === TriggersTypes.sectorRightToLeftEnd

    let sign = 0
    if (leftToRight && offsetFromIdeal < 0) sign = 1
    if (rightToLeft && offsetFromIdeal > 0) sign = -1

    const { coef, frames } = movementConfig.exitTurnPenalty
    const exitTurnPenalty = coef * Math.abs(offsetFromIdeal) * sign

    if (exitTurnPenalty !== 0) {

      this.exitTurnPenalty = exitTurnPenalty
      this.exitTurnPenaltyFrame = frames
      console.log(`Penalizacia za idealny offset ${exitTurnPenalty}, offset ${offsetFromIdeal}`)

    }

  }

  /**
   * Penalizacia za vyjazd zo zakruty
   */
  private updateExitTurnPenalty(): void {

    if (this.exitTurnPenaltyFrame > 0) {

      this.exitTurnPenaltyFrame--
      this.actualPercent += this.exitTurnPenalty
      this.actualPercentFront += this.exitTurnPenalty

      if (this.exitTurnPenaltyFrame === 0) console.log('skoncila penalizacia za vyjazd')

    }

  }

  /**
   * Sila odrazu od mantinela
   */
  private updateReboundForce(): void {

    if (this.reboundForceFrame > 0) {

      this.reboundForceFrame--
      this.actualPercent += this.reboundForce
      const sideCoef = this.reboundForce < 0 ? -1 : 1

      const reboundFront = movementConfig.stepFrontAfterImpact / this.reboundForceDivider *
                sideCoef
      this.actualPercentFront += (this.reboundForce + reboundFront)

      if (this.reboundForceFrame === 0) console.log('skoncila rebound sila')

    }

  }

  /**
   * Aktualizovanie sil v zakrute
   */
  private updateTurnForces(sectorType: TriggersTypes): void {

    // penalizacia za vyjazd zo zakruty
    this.updateExitTurnPenalty()

    // po narazeni na mantinel sa vypne odstrediva aj dostrediva sila
    const afterImpactFreeze = this.reboundForceFrame > 0
    const withoutForces = !this.inTurn || !this.inFullTurn || afterImpactFreeze

    if (withoutForces && !triggersManager.inSlightTurn) {

      mainState().$patch({
        centrifugalForce: 0,
        centripetalForce: 0
      })
      return

    }

    const speed = speedManager.getActualSpeed()
    if (speed < movementConfig.minCentrifugalSpeed) {

      this.updateCentripetalForce(speed, sectorType)

    } else {

      this.updateCentrifugalForce(speed, sectorType)

    }

  }

  /**
   * Poriesenie dostredivej sily
   * @param speed - aktualna rychlost
   * @param sectorType - Typ sektoru
   */
  private updateCentripetalForce(
    speed: number,
    sectorType: TriggersTypes
  ): void {

    // v jemnej zakrute nedavame dostredivu silu, iba odstredivu
    if (triggersManager.inSlightTurn) return

    // znamienko, ci davame dostredivu silu vlavo alebo vpravo (je to opacne ako odstrediva)
    const sign = sectorType === TriggersTypes.sectorLeftToRightEnd ? 1 : -1

    const { centripetalForceMax, minCentrifugalSpeed } = movementConfig
    const centripetalForce = (centripetalForceMax - (centripetalForceMax *
            (speed / minCentrifugalSpeed))) * sign

    mainState().$patch({
      centrifugalForce: 0,
      centripetalForce
    })

    this.actualPercent += centripetalForce
    this.actualPercentFront += centripetalForce

  }

  /**
   * Poriesenie odstredivej sily
   * @param speed - aktualna rychlost
   * @param sectorType - Typ sektoru
   */
  private updateCentrifugalForce(
    speed: number,
    sectorType: TriggersTypes
  ): void {

    // znamienko, ci davame odstredivu silu vlavo alebo vpravo
    const isLeftToRight = sectorType === TriggersTypes.sectorLeftToRightEnd ||
            triggersManager.slightTurnType === SectionTypes.leftToRight
    const sign = isLeftToRight ? -1 : 1

    const {
      centrifugalForceMax, centrifugalForceMaxSteering, maxSpeedCoef, minCentrifugalSpeed,
      centrifugalForceMaxSlightTurn
    } = movementConfig
    const speedValue = speed - minCentrifugalSpeed
    const mobileInputsX = movementState().positionX
    const inputLeft = inputsManager.moveDirectionLeft
    const inputRight = inputsManager.moveDirectionRight
    const inputLeftPressed = !inputRight && (inputLeft || mobileInputsX < 0)
    const inputRightPressed = !inputLeft && (inputRight || mobileInputsX > 0)
    const oppositeInput = (sign === 1 && inputLeftPressed) || (sign === -1 && inputRightPressed)
    let centrifugalForceMaxValue = triggersManager.inSlightTurn ?
      centrifugalForceMaxSlightTurn :
      centrifugalForceMax
    if (oppositeInput) centrifugalForceMaxValue = centrifugalForceMaxSteering
    console.log('centrifugal force max value ', centrifugalForceMaxValue)
    const centrifugalForce = centrifugalForceMaxValue * (speedValue / maxSpeedCoef) * sign

    mainState().$patch({
      centrifugalForce,
      centripetalForce: 0
    })

    this.actualPercent += centrifugalForce
    this.actualPercentFront += centrifugalForce

  }

  /**
   * Vratenie offsetu percent kvoli zakrutam
   * @param actualPercent - Hodnota aktualnych percent na vypocet
   * @param inTurn - Ci je v zakrute
   * @param fullTurnType - typ zakruty (plna, start, end)
   * @returns Offset %
   */
  private getOffsetPercent(
    actualPercent: number,
    inTurn: boolean,
    fullTurnType: SectorFullTurnTypes
  ): number {

    if (!inTurn) return 0

    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorFullTurnConfig = hillLineNormalizer.getSectorFullTurnConfig()
    const sectorConfig = hillLineNormalizer.getSectorConfig()
    const actualIndex = hillLineNormalizer.getActualSectorIndex()

    let offsetPercent = movementConfig.offsetPercent

    // na zaciatku zakruty davame offsetPercent v rozmedzi 0 do default, podla pozicie
    if (fullTurnType === SectorFullTurnTypes.minimumOnStart) {

      const startPercent = sectorConfig[actualIndex - 1].points[CurveTypes.left]
      const endPercent = sectorFullTurnConfig[actualIndex - 1].points[CurveTypes.left]
      const percent = (actualPercent - startPercent) / (endPercent - startPercent)

      offsetPercent *= percent

    }

    // na konci zakruty davame offsetPercent v rozmedzi default do 0, podla pozicie
    if (fullTurnType === SectorFullTurnTypes.minimumOnEnd) {

      const startPercent = sectorFullTurnConfig[actualIndex].points[CurveTypes.left]
      const endPercent = sectorConfig[actualIndex].points[CurveTypes.left]
      const percent = (actualPercent - startPercent) / (endPercent - startPercent)

      offsetPercent *= (1 - percent)

    }

    return offsetPercent

  }

  /**
   * Vypocitanie limitov, kvoli zakrutam
   * @param offsetPercent - Offset % kvoli zakrutam
   */
  private calculateLimits(offsetPercent: number): void {

    if (!this.inTurn) {

      this.limitLeft = movementConfig.limitLeft
      this.limitRight = movementConfig.limitRight
      return

    }

    const percentInTurn = player.hillLinesManager.getPercentInTurn()

    // v pravotocivej zakrute akoby predlzujeme min + menime limity
    if (this.inTurnLeftToRight) {

      const minRight = movementConfig.limitRight
      const maxRight = movementConfig.limitRightInner

      this.limitLeft = movementConfig.limitLeft - offsetPercent
      this.limitRight = minRight + ((maxRight - minRight) * percentInTurn)

    }

    // v lavotocivej zakrute akoby predlzujeme max + menime limity
    if (this.inTurnRightToLeft) {

      const minLeft = movementConfig.limitLeft
      const maxLeft = movementConfig.limitLeftInner

      this.limitLeft = minLeft + ((maxLeft - minLeft) * percentInTurn)
      this.limitRight = movementConfig.limitRight + offsetPercent

    }

  }

  /**
   * Vypocitanie virtualnych percent, kvoli zakrutam
   * @param actualPercent - Aktualne %
   * @param offsetPercent - Offset % kvoli zakrutam
   * @returns Virtualne %
   */
  private calculateVirtualPercent(
    actualPercent: number,
    offsetPercent: number,
    inTurn: boolean,
    inTurnLeftToRight: boolean,
    inTurnRightToLeft: boolean
  ): number {

    if (!inTurn) return actualPercent

    let min = 0
    let max = 1

    // v pravotocivej zakrute akoby predlzujeme min + menime limity
    if (inTurnLeftToRight) {

      min = 0 - offsetPercent
      max = 1

    }

    // v lavotocivej zakrute akoby predlzujeme max + menime limity
    if (inTurnRightToLeft) {

      min = 0
      max = 1 + offsetPercent

    }

    // ked sme v zakrute, tak musime spravit prepocitanie percent, podla typu zakruty
    return (actualPercent - min) / (max - min)

  }

  /**
   * Sprava auto movementu
   */
  public manageAutoMove(): void {

    if (!gameConfig.idealAutoMove.drive) return

    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorData = hillLineNormalizer.getActualSectorData()
    if (this.inTurn && this.inFullTurn) {

      const actualSectorIndex = hillLineNormalizer.getActualSectorIndex()
      const percentWide = player.hillLinesManager.getOffsetForIdealLineInTurn(
        actualSectorIndex,
        sectorData.type
      )
      this.setActualPercent(percentWide)
      this.setActualPercentFront(percentWide)

    } else {

      this.setActualPercent(0.5)
      this.setActualPercentFront(0.5)

    }

  }

  /**
   * Prepocitanie aktualnych % do virtualnych
   * @param actualPercent - Aktualne %
   * @param linePercent - % na krivke podla daneho sektora
   * @param sectorType - Typ sektoru
   * @param sectorIndex - Index sektoru
   * @returns Virtualne %
   */
  public convertActualToVirtual(
    actualPercent: number,
    linePercent: number,
    sectorType: TriggersTypes,
    sectorIndex: number
  ): number {

    const inTurnLeftToRight = sectorType === TriggersTypes.sectorLeftToRightEnd
    const inTurnRightToLeft = sectorType === TriggersTypes.sectorRightToLeftEnd
    const inTurn = inTurnLeftToRight || inTurnRightToLeft
    const fullTurnType = triggersManager.getFullTurnTypeByPercent(linePercent, sectorIndex)

    return this.calculateVirtualPercent(
      actualPercent,
      this.getOffsetPercent(linePercent, inTurn, fullTurnType),
      inTurn,
      inTurnLeftToRight,
      inTurnRightToLeft
    )

  }

  /**
   * Aktualizovanie veci
   */
  public update(): void {

    if (!this.moveLeftRight) return

    if (this.countFramesAfterImpact) {

      if (this.framesAfterFirstImpact >= tutorialConfig.afterImpactDelay && !tutorialFlow.firstSideCollisionEnded) {

        tutorialFlow.eventActionTrigger(TutorialEventType.sideCollision)
        this.countFramesAfterImpact = false

      }
      this.framesAfterFirstImpact += 1

    }

    // zistime, ci je v zakrute
    const hillLineNormalizer = player.hillLinesManager.getHillLineNormalizer()
    const sectorData = hillLineNormalizer.getActualSectorData()
    this.inTurnLeftToRight = sectorData.type === TriggersTypes.sectorLeftToRightEnd
    this.inTurnRightToLeft = sectorData.type === TriggersTypes.sectorRightToLeftEnd
    this.inTurn = this.inTurnLeftToRight || this.inTurnRightToLeft
    this.inFullTurn = hillLineNormalizer.fullTurnType === SectorFullTurnTypes.fullOnMiddle

    // ak nie je automove pre idealny prechod vsetkym
    if (!gameConfig.idealAutoMove.drive) {

      const mobileInputsX = movementState().positionX

      if (inputsManager.moveDirectionLeft && inputsManager.moveDirectionRight) {

        playerAnimationManager.turningStraight()
        this.previousPressed = undefined

      } else if (inputsManager.moveDirectionLeft || mobileInputsX < 0) {

        this.toLeftSide(this.inTurn)

      } else if (inputsManager.moveDirectionRight || mobileInputsX > 0) {

        this.toRightSide(this.inTurn)

      } else {

        playerAnimationManager.turningStraight()

      }

      playerAnimationManager.afterEvalTurning()

      this.updateTurnForces(sectorData.type)
      this.updateReboundForce()

    }

    const offsetPercent = this.getOffsetPercent(
      player.hillLinesManager.getActualPercent(),
      this.inTurn,
      hillLineNormalizer.fullTurnType
    )

    this.calculateLimits(offsetPercent)

    this.frameCounterAfterImpact += 1
    this.checkLimitsLeft()
    this.checkLimitsRight()

    this.virtualPercent = this.calculateVirtualPercent(
      this.actualPercent,
      offsetPercent,
      this.inTurn,
      this.inTurnLeftToRight,
      this.inTurnRightToLeft
    )

    this.virtualPercentFront = this.calculateVirtualPercent(
      this.actualPercentFront,
      offsetPercent,
      this.inTurn,
      this.inTurnLeftToRight,
      this.inTurnRightToLeft
    )

    // upravime backwardForce, ak treba
    speedManager.setForceBackward(Math.round(Math.abs(this.actualPercent - this.actualPercentFront) * 1000) / 1000)

  }

  /**
   * reset
   */
  public reset(): void {

    this.actualPercent = movementConfig.center
    this.actualPercentFront = movementConfig.center
    this.virtualPercent = movementConfig.center
    this.virtualPercentFront = movementConfig.center

  }

}

export const playerMovementManager = new PlayerMovementManager()
