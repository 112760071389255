import { defineStore } from 'pinia'

export interface MainState {
  speed: number,
  centrifugalForce: number,
  centripetalForce: number,
  slope: number,
  offsetFromIdeal: number
}

const initialState = (): MainState => ({
  speed: 1,
  centrifugalForce: 0,
  centripetalForce: 0,
  slope: 0,
  offsetFromIdeal: 0
})

export const mainState = defineStore('mainState', {
  state: initialState
})
